import React, { FC, FormEvent } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { difference } from 'ramda';
import { bemPrefix, If } from 'src/utils';
import { AppInteractionData, InteractionType } from 'src/types/core';
import { Button, HoverTooltip, Loading } from 'src/components/molecules';
import { eventNameToHuman } from 'src/utils/events';
import { GtmID } from 'src/types/google-tag-manager';
import { PopoverPlacement } from 'src/components/molecules/popover/simple-popover';
import { getIsSurveyActivation } from 'src/selectors/surveys-multi-apps.selectors';
import { State } from 'src/reducers';
import { loveDialogCodePoints, SurveyMultiAppsModel } from 'src/reducers/surveys-multi-apps/survey-multi-apps.model';
import { getCurrentUserId } from 'src/selectors/user';
import { getCurrentOrg, getOrganizationApps } from 'src/selectors/organizations';
import { ImtModel } from 'src/reducers/imt/imt.model';
import { getAppsList } from 'src/selectors/current_app';
import { getPromptList } from 'src/selectors/prompts-multi-apps.selectors';
import { PromptMultiAppsItem } from 'src/reducers/prompts-multi-apps';
import { ImtListStatusModal } from './imt-list-status-modal';

import './imt-list-row.scss';

const bem = bemPrefix('imt-list-row');

export interface ImtListElement {
  id: string;
  active: boolean;
  interactionData: AppInteractionData[];
  isValidTargeting: boolean;
  responseCount?: number;
  actionsCount?: number;
  responseRate?: number;
  responseRateCachedTime?: string;
  title?: string;
  name: string;
  updatedAt?: string;
  selfTargeting?: boolean;
  createdBy: string;
}

export interface ImtListRowProps {
  type: InteractionType;
  imtListElement: ImtListElement;
  path: string;
  currentAppId: string;
  handleActivate?: (event: FormEvent<HTMLButtonElement>) => void;
  handleDeactivate?: (event: FormEvent<HTMLButtonElement>) => void;
  handleDelete: (event: FormEvent<HTMLButtonElement>) => void;
}

export const ImtListRow: FC<ImtListRowProps> = ({
  type,
  imtListElement,
  path,
  currentAppId,
  handleDeactivate,
  handleActivate,
  handleDelete,
}) => {
  const isLoading = useSelector((state: State) => getIsSurveyActivation(state, imtListElement.id));
  const userId = useSelector((state: State) => getCurrentUserId(state));
  const organizationApps = useSelector((state: State) => getOrganizationApps(state)) || [];
  const { billing_contact_id = '', id = '' } = useSelector((state: State) => getCurrentOrg(state));
  const allApps = useSelector((state: State) => getAppsList(state)) || [];
  const imtPrompts = useSelector((state: State) => getPromptList(state));

  const isActive = imtListElement.interactionData.some(({ active }) => active);
  const displayRate = imtListElement.responseRate ? `${(imtListElement.responseRate * 100).toPrecision(3)}%` : '0%';

  const isSurvey = type === InteractionType.Survey;
  const isPrompt = type === InteractionType.TextModal;

  const triggeredBy = isSurvey
    ? getCurrentAppInteractionData(imtListElement.interactionData, currentAppId)?.triggeredBy || []
    : [];

  const userPermisson = ImtModel.getUserPermissions(
    { interactionData: imtListElement.interactionData, createdBy: imtListElement.createdBy },
    userId,
    organizationApps.map(({ id }) => id),
  );

  const userRole = ImtModel.getUserRole(
    { interactionData: imtListElement.interactionData },
    userId,
    billing_contact_id,
    SurveyMultiAppsModel.getAppRolesMap(id, allApps),
  );

  const hasActionBtns = ImtModel.getIsUserNotReporterWithAccessToAllApps(userPermisson, userRole);

  const renderStatus = () => {
    const activeAppsCount = imtListElement.interactionData.filter((int) => int.active).length;
    const inactiveAppsCount = imtListElement.interactionData.length - activeAppsCount;
    const completedAppsCount = imtListElement.interactionData.filter(
      (int) => int.responseCount && int.responseCount > 0 && !int.active,
    ).length;

    if (isActive) {
      return (
        <div className={bem('status-block')}>
          <span className={bem('status-block', 'active')}>Active</span>
          <span className={bem('status-block__hint')}>
            {`In ${activeAppsCount} ${activeAppsCount === 1 ? 'App' : 'Apps'}`}
          </span>
        </div>
      );
    }

    if (imtListElement.responseCount && imtListElement.responseCount > 0) {
      return (
        <div className={bem('status-block')}>
          <span className={bem('status-block', 'completed')}>Completed</span>
          <span className={bem('status-block__hint')}>
            {`In ${completedAppsCount} ${completedAppsCount === 1 ? 'App' : 'Apps'}`}
          </span>
        </div>
      );
    }

    return (
      <div className={bem('status-block')}>
        <span className={bem('status-block', 'inactive')}>{isPrompt ? 'Paused' : 'Inactive'}</span>
        <span className={bem('status-block__hint')}>
          {`In ${inactiveAppsCount} ${inactiveAppsCount === 1 ? 'App' : 'Apps'}`}
        </span>
      </div>
    );
  };

  const getWhereDescription = () => {
    const eventsSet = new Set<string>();
    if (imtListElement.selfTargeting) {
      imtListElement.interactionData.forEach((int) =>
        ((int.codePoints as string[]) || []).forEach((cp) => eventsSet.add(cp)),
      );
    } else {
      (
        (getCurrentAppInteractionData(imtListElement.interactionData, currentAppId)?.codePoints as string[]) || []
      ).forEach((cp) => eventsSet.add(cp));
    }

    const filteredEvents = difference(Array.from(eventsSet), loveDialogCodePoints());

    const eventDescriptions: React.ReactNode[] = filteredEvents.length ? filteredEvents.map(eventNameToHuman) : [];

    let numPromptsInOtherApps = 0;
    const seenPromptUnifiedIds = new Set();

    if (!imtListElement.selfTargeting) {
      for (const int of imtListElement.interactionData) {
        for (const trigger of int.triggeredBy || []) {
          switch (trigger.type) {
            case InteractionType.EnjoymentDialog:
              eventDescriptions.push(<NavLink to={`/apps/${int.appId}/love-prompt`}>Love Dialog: No</NavLink>);
              break;

            case InteractionType.TextModal:
              {
                const unifiedPromptId = getImtPromptIdByInteractionId(trigger.id, imtPrompts);
                if (unifiedPromptId && !seenPromptUnifiedIds.has(unifiedPromptId)) {
                  seenPromptUnifiedIds.add(unifiedPromptId);
                  eventDescriptions.push(
                    <NavLink to={`/apps/${currentAppId}/prompts/${unifiedPromptId}`} title="Prompt">
                      Prompt
                    </NavLink>,
                  );
                } else {
                  numPromptsInOtherApps += 1;
                }
              }
              break;

            default:
          }
        }
      }
    }

    if (numPromptsInOtherApps > 0) {
      eventDescriptions.push(<>Prompts in other apps</>);
    }

    if (eventDescriptions.length > 5) {
      eventDescriptions.splice(5, eventDescriptions.length - 5, <>and more</>);
    }

    return eventDescriptions.length
      ? eventDescriptions.reduce((accum: React.ReactNode[], el) => (accum.length ? [...accum, <>, </>, el] : [el]), [])
      : '--';
  };

  const hasCodePoints = imtListElement.interactionData.some((int) => int.codePoints && int.codePoints.length > 0);
  const isNotCompletedImt = imtListElement.selfTargeting === null || imtListElement.selfTargeting === undefined;
  const isIndependentIntDisabled = imtListElement.selfTargeting && !hasCodePoints;
  const isActivateDisabled = isNotCompletedImt || isIndependentIntDisabled;

  const isLinkedToLdNoSection = imtListElement.interactionData.some(
    (int) => int.codePoints && int.codePoints.includes('enjoyment_dialog.no'),
  );

  const itemName = imtListElement.title || imtListElement.name;

  return (
    <>
      {isLoading && <Loading className={bem('loader')} title="" isSmall />}
      <tr key={imtListElement.id} className={`${bem()} ${isLoading ? bem('loading') : ''}`}>
        <td className={bem('name')}>
          {isLoading ? (
            <span>{itemName}</span>
          ) : (
            <NavLink
              className="name link"
              gtm-id={isSurvey ? GtmID.InteractionSurveySelect : GtmID.InteractionNoteSelect}
              to={path}
            >
              {itemName}
            </NavLink>
          )}
        </td>
        <td className={bem('updated-at')}>
          <abbr title={imtListElement.updatedAt}>{moment(imtListElement.updatedAt).fromNow()}</abbr>
        </td>
        <td className={bem('events')}>{getWhereDescription()}</td>
        {isSurvey && <td className={bem('response-count')}>{imtListElement.responseCount}</td>}
        {isPrompt && <td className={bem('actions-count')}>{imtListElement.actionsCount}</td>}
        {isSurvey && (
          <td className={bem('response-rate')}>
            <>
              {imtListElement.responseRateCachedTime ? (
                <div>
                  <span className="rate">{displayRate}</span>
                  <span> as of </span>
                  <span className={bem('timeago')} title={imtListElement.responseRateCachedTime}>
                    {moment(imtListElement.responseRateCachedTime).fromNow()}
                  </span>
                </div>
              ) : (
                <span>Not yet available</span>
              )}
            </>
          </td>
        )}
        <td className={bem('status')}>
          {isLoading ? (
            renderStatus()
          ) : (
            <HoverTooltip
              content={isLoading ? null : <ImtListStatusModal imtApps={imtListElement.interactionData} type={type} />}
              placement={PopoverPlacement.bottomStart}
              className={bem('tooltip')}
            >
              {renderStatus()}
            </HoverTooltip>
          )}
        </td>
        <If condition={hasActionBtns && isSurvey}>
          <td className={bem('actions')}>
            <If condition={!isActive}>
              <Button
                className={bem('button-action')}
                gtm-id={isSurvey ? GtmID.InteractionSurveyUnArchive : ''}
                data-survey-id={imtListElement.id}
                onClick={handleActivate}
                disabled={isLoading || isActivateDisabled}
              >
                Unarchive
              </Button>
            </If>
            <If condition={isActive && triggeredBy.length === 0 && !isLinkedToLdNoSection}>
              <Button
                className={bem('button-action')}
                gtm-id={isSurvey ? GtmID.InteractionSurveyArchive : ''}
                data-survey-id={imtListElement.id}
                onClick={handleDeactivate}
                disabled={isLoading}
              >
                Archive
              </Button>
            </If>
          </td>
        </If>
        <If condition={!hasActionBtns && isSurvey}>
          <>
            <td className={bem('actions')}>
              <div />
            </td>
            <td className={bem('delete-action')}>
              <div />
            </td>
          </>
        </If>
        <If condition={hasActionBtns}>
          <td className={bem('delete-action')}>
            <Button
              className={bem('button-action')}
              gtm-id={isSurvey ? GtmID.InteractionSurveyDelete : GtmID.InteractionNoteDelete}
              data-survey-id={imtListElement.id}
              onClick={handleDelete}
              disabled={isLoading}
            >
              Delete
            </Button>
          </td>
        </If>
      </tr>
    </>
  );
};

function getCurrentAppInteractionData(interactionData: AppInteractionData[], currentAppId: string) {
  return interactionData.find(({ appId }) => appId === currentAppId);
}

function getImtPromptIdByInteractionId(id: string, prompts: PromptMultiAppsItem[]) {
  return prompts.find((prompt) => prompt.interactionData.find(({ interactionId }) => interactionId === id))?.id || '';
}
